* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: beige;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calculator {
  background-color: rgb(61, 61, 61);
  width: 320px;
  padding: 32px;
  margin-top: 64px;
  border-radius: 16px;
  box-shadow: 9px 16px 15px 14px rgba(0, 0, 0, 0.1);
}

.display {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
}

.display input {
  width: 100%;
  height: 60px;
  text-align: right;
  font-size: 30px;
  padding: 5px 10px;
  background-color: rgb(104, 102, 102);
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: bold;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  background-color: rgb(61, 61, 61);
}

.button {
  background-color: rgb(143, 141, 141);
  height: 60px;
  color: white;
  font-size: 20px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(41, 41, 41);
  border-radius: 10px;
}

.equals {
  background-color: rgb(105, 168, 147);
  grid-column: span 2;
  border-radius: 20px;
}